import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

const now = new Date()

export const LayoutFooter = () => {
  const { t } = useTranslation(['common'])
  const { typography } = useTheme()

  return (
    <Box bgcolor="primary.main" paddingY={{ xs: 5, sm: 10 }} textAlign="center">
      <Container>
        <Typography
          variant="body2"
          marginTop={1}
          color="common.white"
          sx={{ opacity: typography.opacity.high }}
        >
          Copyright © {now.getFullYear()}. {t('common:allRightsReserved')}
        </Typography>
      </Container>
    </Box>
  )
}
