import styled from '@emotion/styled'
import LogoCircle from 'assets/images/logo/LogoCircle.svg'
import { PRIMARY_COLOR } from 'styles/colors'

export const SLogo = styled(LogoCircle)`
  position: absolute;
  bottom: -50%;
  right: -15%;
  height: 110vh;
  z-index: -1;
  fill: ${PRIMARY_COLOR};
  opacity: 0.1;
`
