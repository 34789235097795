import Box from '@mui/material/Box'
import { FC, PropsWithChildren } from 'react'
import { SLogo } from './Layout.styled'
import { LayoutFooter } from './LayoutFooter'

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => (
  <>
    <Box position="relative" overflow="hidden" minHeight="100vh">
      <SLogo />

      <main>{children}</main>
    </Box>

    <LayoutFooter />
  </>
)
