'use client'

import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { LINKS } from 'constants/links'
import { useLocalizedPath } from 'hooks/i18n/useLocalizedPath'
import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseLayout } from './BaseLayout'
import { SLogoCircle } from './LayoutAuth.styled'
import { LayoutAuthLanguageSwitcher } from './LayoutAuthLanguageSwitcher'
import { LayoutAuthTitle } from './LayoutAuthTitle'

interface LayoutAuthProps {
  title: string
  subtitle: string
  displayTitleLogo?: boolean
  displayBackButton?: boolean
}

export const Layout: FC<PropsWithChildren<LayoutAuthProps>> = ({
  children,
  title,
  subtitle,
  displayTitleLogo,
  displayBackButton
}) => {
  const { i18n } = useTranslation()
  const getLocalizedPath = useLocalizedPath(i18n.language)

  return (
    <BaseLayout>
      <Container sx={{ position: 'relative' }} maxWidth="xl">
        <Stack
          minHeight="100vh"
          pt={8}
          pb={8}
          alignItems="center"
          justifyContent="center"
        >
          <Box position="absolute" right={0} top={0}>
            <LayoutAuthLanguageSwitcher />
          </Box>

          <Box sx={{ width: '100%', maxWidth: '520px' }} component="section">
            <Paper
              sx={{
                paddingX: { xs: 3, sm: 7 },
                paddingY: { xs: 4, sm: 7 },
                position: 'relative'
              }}
              elevation={12}
            >
              {displayBackButton && (
                <IconButton href={getLocalizedPath(LINKS.login)}>
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </IconButton>
              )}

              <SLogoCircle />

              <LayoutAuthTitle
                title={title}
                subtitle={subtitle}
                displayLogo={displayTitleLogo}
              />

              {children}
            </Paper>
          </Box>
        </Stack>
      </Container>
    </BaseLayout>
  )
}
