'use client'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Layout } from 'components/layout/Layout'
import { LINKS } from 'constants/links'
import { useLocalizedPath } from 'hooks/i18n/useLocalizedPath'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const NotFound: FC = () => {
  const { t, i18n } = useTranslation(['common', 'errorPage'])
  const getLocalizedPath = useLocalizedPath(i18n.language)

  return (
    <Layout
      title={t('errorPage:notFound.title')}
      subtitle={t('errorPage:notFound.subtitle')}
    >
      <Box textAlign="center">
        <Button
          variant="contained"
          size="large"
          href={getLocalizedPath(LINKS.login)}
        >
          {t('common:backToHomepage')}
        </Button>
      </Box>
    </Layout>
  )
}
