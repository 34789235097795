'use client'

import { Resource, createInstance } from 'i18next'
import { FC, PropsWithChildren } from 'react'
import { I18nextProvider } from 'react-i18next'
import { initTranslations } from 'utils/i18n'

interface TranslationsProviderProps extends PropsWithChildren {
  locale: string
  namespaces: string[]
  resources: Resource
}

export const TranslationsProvider: FC<TranslationsProviderProps> = ({
  children,
  locale,
  namespaces,
  resources
}) => {
  const i18n = createInstance()

  initTranslations(locale, namespaces, i18n, resources)

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
