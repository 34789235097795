import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { usePathname, useRouter } from 'next/navigation'
import { useTranslation } from 'react-i18next'
import i18nConfig from '../../../i18n.config'

export function LayoutAuthLanguageSwitcher() {
  const { i18n } = useTranslation()
  const currentLocale = i18n.language ?? i18nConfig.defaultLocale
  const router = useRouter()
  const currentPathname = usePathname()

  const locales = i18nConfig.locales

  const handleChange = (newLocale: string) => {
    // set cookie for next-i18n-router
    const days = 30
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    const expires = '; expires=' + date.toUTCString()
    document.cookie = `NEXT_LOCALE=${newLocale};expires=${expires};path=/`

    // redirect to the new locale path
    if (
      currentLocale === i18nConfig.defaultLocale &&
      !currentPathname.includes(i18nConfig.defaultLocale)
    ) {
      router.push('/' + newLocale + currentPathname, { scroll: false })
    } else {
      router.push(
        currentPathname.replace(`/${currentLocale}`, `/${newLocale}`),
        { scroll: false }
      )
    }
  }

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        gap={1}
        py={{ xs: 0.5, sm: 1 }}
        alignItems="center"
      >
        {locales?.map((locale, index) => (
          <Stack direction="row" alignItems="center" key={locale} gap={1}>
            <Button
              disabled={locale === currentLocale}
              size="small"
              sx={{
                minWidth: 0,
                px: 0.5,
                color: 'text.secondary',
                '&.Mui-disabled': {
                  color: 'primary.main'
                }
              }}
              onClick={() => handleChange(locale)}
            >
              {locale.toUpperCase()}
            </Button>

            {index < locales.length - 1 && (
              <Box
                minWidth="4px"
                height="4px"
                bgcolor="text.primary"
                sx={{ borderRadius: '3px' }}
              />
            )}
          </Stack>
        ))}
      </Stack>
    </Container>
  )
}
